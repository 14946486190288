






















.legend {
  width: 50%;
}
