













.daily-balance-chart {
  .chart-content {
    width: 100%;
    overflow: auto;
  }
}
