













































.status-summary-grid {
  .grid-table {
    width: max-content;

    /deep/ table {
      border-spacing: 1px;
      margin-bottom: 0;

      thead {
        td {
          padding: 3px;
        }
      }
      td {
        padding: 0;
        text-align: center;
      }
    }

    .td-date {
      width: 120px;
      vertical-align: middle;
    }
    .td-status {
      .status-item {
        display: inline-block;
        cursor: pointer;
        padding: 3px;
        width: 100%;
      }
    }
  }
}
