














.daily-balance-chart {
  .chart-content {
    width: 100%;
  }
}
